import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

export default function PrivacyPolicy() {
  return (
    <div className="pt-20 relative pb-20"> {/* Add padding top to accommodate the fixed header */}
      <Header name="Scoobiedoobie Privacy Policy"/>
      <div className="px-6 pt-24 sm:pt-32 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          <p className="text-gray-600 mb-8">Last updated: August 24, 2024</p>
          <p className="text-gray-600 mb-8">
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
          </p>
          <p className="text-gray-600 mb-8">
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Interpretation and Definitions</h2>

          <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Interpretation</h3>
          <p className="text-gray-600 mb-4">
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
          </p>

          <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Definitions</h3>
          <p className="text-gray-600 mb-4">
            For the purposes of this Privacy Policy:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
            <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
            <li><strong>Application</strong> refers to scoobiedoobie, the software program provided by the Company.</li>
            <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to scoobiedoobie.</li>
            <li><strong>Country</strong> refers to: New York, United States</li>
            <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
            <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
            <li><strong>Service</strong> refers to the Application.</li>
            <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
            <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Collecting and Using Your Personal Data</h2>

          <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Types of Data Collected</h3>

          <h4 className="text-lg font-semibold text-gray-700 mt-4 mb-2">Personal Data</h4>
          <p className="text-gray-600 mb-4">
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
          </ul>

          <h4 className="text-lg font-semibold text-gray-700 mt-4 mb-2">Usage Data</h4>
          <p className="text-gray-600 mb-4">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="text-gray-600 mb-4">
            Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          </p>
          <p className="text-gray-600 mb-4">
            When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
          </p>
          <p className="text-gray-600 mb-4">
            We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
          </p>

          <h4 className="text-lg font-semibold text-gray-700 mt-4 mb-2">Information Collected while Using the Application</h4>
          <p className="text-gray-600 mb-4">
            While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>Information regarding your location</li>
            <li>Pictures and other information from your Device's camera and photo library</li>
          </ul>
          <p className="text-gray-600 mb-4">
            We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.
          </p>
          <p className="text-gray-600 mb-4">
            You can enable or disable access to this information at any time, through Your Device settings.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Use of Your Personal Data</h2>
          <p className="text-gray-600 mb-4">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li><strong>To provide and maintain our Service:</strong> including to monitor the usage of our Service.</li>
            <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
            <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
            <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
            <li><strong>To provide You with news, special offers and general information:</strong> about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
            <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
            <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
            <li><strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Disclosure of Your Personal Data</h2>

          <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Business Transactions</h3>
          <p className="text-gray-600 mb-4">
            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
          </p>

          <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Law Enforcement</h3>
          <p className="text-gray-600 mb-4">
            Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
          </p>

          <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Other Legal Requirements</h3>
          <p className="text-gray-600 mb-4">
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Security of Your Personal Data</h2>
          <p className="text-gray-600 mb-4">
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Children's Privacy</h2>
          <p className="text-gray-600 mb-4">
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
          </p>
          <p className="text-gray-600 mb-4">
            If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Links to Other Websites</h2>
          <p className="text-gray-600 mb-4">
            Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
          </p>
          <p className="text-gray-600 mb-4">
            We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Changes to this Privacy Policy</h2>
          <p className="text-gray-600 mb-4">
            We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="text-gray-600 mb-4">
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
          </p>
          <p className="text-gray-600 mb-4">
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Contact Us</h2>
          <p className="text-gray-600 mb-4">
            If you have any questions about this Privacy Policy, You can contact us:
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>By visiting this page on our website: <a href="https://scoobiedoobie.com/contact" className="text-blue-600 underline">https://scoobiedoobie.com/contact</a></li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

