import React, { useState } from 'react'
import Loading from '../Animations/Loading.json';
import Done from '../Animations/Done.json';
import Warn from '../Animations/Warn.json';


import { Player } from '@lottiefiles/react-lottie-player';
import VideoPlayer from '../component/VidePlayer';
export default function BetaTester() {
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [isSending, setisSending] = useState(false);
    const [response, setresponse] = useState(null)
    const handlePhone = (value) => {
        const isNumeric = /^\d+$/;
        if(isNumeric.test(value) || value==="") {
            setPhone(value)
        }
    } 
    
    const resetAll = () => {
        setName("");
        setPhone("");
        setisSending(false);
        setresponse(null);
    }
    
    const handleName = (value) => {
        const isAlphabetic = /^[A-Z a-z]+$/;
        if(isAlphabetic.test(value) || value==="") {
            setName(value)
        }
    } 

    const sendUser = async () => {
        setisSending(prev => !prev);
        if(phone.length===10 && name.length>2) {

            const formData = {
                name, phone
            }

            
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            const raw = JSON.stringify(formData);
            
            const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
            };
            
            await fetch("https://scoobiedoobieserver.azurewebsites.net/api/PreRegisteredUser/beta", requestOptions)
            .then((response) => response.text())
            .then(async(result) => 
            {
                setresponse(result)
            }
            )
            .finally(
                setisSending(prev => !prev)
            )
            .catch((error) => console.error(error));


        }else{
        setisSending(prev => !prev);
        }
    }
  return (
    <div className='h-screen w-full flex justify-center items-center  bg-black relative p-4'>
        <div className='w-full h-screen saturate-0 fixed bg-black opacity-15 '>
        <VideoPlayer
                src="https://scoobiedoobieuserdata.blob.core.windows.net/userdata/8303104-hd_1280_720_24fps.mp4"
                type="video/mp4"
                className="h-full w-full bg-black "
                
            />
        </div>
        {isSending ?    
        <Player src={Loading} className="player w-60 h-60" loop autoplay />
           : 
       <div className='absolute md:p-20 p-5 md:bg-black bg-transparent max-w-full md:bg-opacity-35 md:backdrop-blur-lg rounded-xl'>

          {response ?  

            <div className='flex items-center flex-col gap-4 max-w-full  w-96'>
                 <Player src={response==="User registered" ? Done : Warn} className="player w-60 h-60" loop autoplay />
                <span className='text-white text-center font-light text-xs'>{response==="User registered" ? "Your account is now register for scoobiedoobie beta app testing program!" : " You are already register for scoobiedoobie beta app testing program!"}</span>

                <span
                onClick={resetAll}
                style={{
                backgroundImage: phone.length===10 && name.length>2 ? "linear-gradient(to right, #d65db1 , #ff6f91)" : "linear-gradient(to right, #888 , #999)" ,
                color: "#fff",
                textAlign: "center",
                }}
                className= "w-full transition-all select-none text-nowrap overflow-hidden cursor-pointer duration-500 rounded-full px-6 py-4" 
                    >
                Register Another
            </span>
            </div>
           : 
          <div className='flex items-center flex-col gap-4 max-w-full  w-96'>
      <a href='https://scoobiedoobie.com/'>
       <img
          src="./images/title.svg"
          alt="title text for logo"
          className="w-full max-w-40, cursor-pointer brightness-150 hover:brightness-200 hover:contrast-200"

        />
    </a> 

        <span className='text-white font-light uppercase text-xs text-center'>Register for Scoobiedoobie beta testing program!</span>
       <input
            value={name}
            onChange={(e) => handleName(e.target.value)}
            type="text"
     
            className={
                name.length>2
                ? "text-md caret-[#ff6f91] text-white w-full bg-transparent  border-2 border-opacity-50 border-white outline-none rounded-full px-6 py-4 placeholder-gray-50 placeholder-opacity-30 text-ellipsis"
                : "text-md caret-red-500 text-red-500 w-full bg-transparent  border-2 border-opacity-50 border-white outline-none rounded-full px-6 py-4 placeholder-gray-50 placeholder-opacity-30 text-ellipsis"
            }
            placeholder="John Doe"
          />
      
       <input
            value={phone}
            onChange={(e) => handlePhone(e.target.value)}
            type="text"
     
            className={
                phone.length===10
                ? "text-md caret-[#ff6f91] text-white w-full bg-transparent  border-2 border-opacity-50 border-white outline-none rounded-full px-6 py-4 placeholder-gray-50 placeholder-opacity-30 text-ellipsis"
                : "text-md caret-red-500 text-red-500 w-full bg-transparent  border-2 border-opacity-50 border-white outline-none rounded-full px-6 py-4 placeholder-gray-50 placeholder-opacity-30 text-ellipsis"
            }
            placeholder="9876543210"
          />
      

            <span
            onClick={sendUser}
            style={{
              backgroundImage: phone.length===10 && name.length>2 ? "linear-gradient(to right, #FA6429 , #F1951A)" : "linear-gradient(to right, #888 , #999)" ,
              color: "#fff",
              textAlign: "center",
            }}
            className={
                phone.length===10 && name.length>2 ?
                "w-full transition-all select-none text-nowrap overflow-hidden cursor-pointer duration-500 rounded-full px-6 py-4"
                :
                "w-full transition-all select-none text-nowrap overflow-hidden cursor-not-allowed duration-500 rounded-full px-6 py-4"

            }
                >
            Register Now
          </span>
          </div>
            }   
        </div>
        }
        <div className="fixed flex justify-center left-0 w-full text-center  md:bottom-2  bottom-0 text-white">
        <span className="text-xs flex-1 md:flex-initial bg-black p-3 md:rounded-full bg-opacity-50">© 2024 Scoobiedoobie, All Rights Reserved</span>
      </div>
    </div>
  )
}
