import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

export default function TermsAndCondition() {
  return (
    <div className="px-6 py-14 sm:py-32 lg:px-8 relative pb-20">
      <Header name="Scoobiedoobie Terms and Confition"/>
      <div>
        <div className="px-6  py-14 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <p className="text-gray-600 mb-8">Last updated: August 24, 2024</p>
            <p className="text-gray-600 mb-8">
              These Terms and Conditions ("Terms") govern your use of our service. By using our service, you agree to these Terms. If you do not agree with any part of these Terms, you may not use our service.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Interpretation and Definitions</h2>

            <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Interpretation</h3>
            <p className="text-gray-600 mb-4">
              The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Definitions</h3>
            <p className="text-gray-600 mb-4">
              For the purposes of these Terms:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li><strong>Service</strong> refers to the Application.</li>
              <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to scoobiedoobie.</li>
              <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Use of the Service</h2>
            <p className="text-gray-600 mb-4">
              You must follow any policies made available to you within the Service. You may use the Service only as permitted by law, including applicable export control laws and regulations. We may suspend or terminate your access if you do not comply with these Terms or if we are investigating suspected misconduct.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">User Obligations</h2>
            <p className="text-gray-600 mb-4">
              You agree to comply with all applicable laws, rules, and regulations when using the Service. You are responsible for your own conduct and any content that you provide to the Service.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Intellectual Property</h2>
            <p className="text-gray-600 mb-4">
              The Service and its original content, features, and functionality are and will remain the exclusive property of the Company and its licensors. You may not copy, modify, distribute, or create derivative works from any part of the Service without our express written permission.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Limitation of Liability</h2>
            <p className="text-gray-600 mb-4">
              In no event shall the Company, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Governing Law</h2>
            <p className="text-gray-600 mb-4">
              These Terms are governed by and construed in accordance with the laws of New York, United States, without regard to its conflict of law principles. Any legal action or proceeding arising out of or related to these Terms or your use of the Service shall be brought exclusively in the courts located in New York.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Changes to These Terms</h2>
            <p className="text-gray-600 mb-4">
              We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Contact Us</h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about these Terms, please contact us:
            </p>
            <ul className="list-disc list-inside text-gray-600">
              <li>By visiting this page on our website: <a href="https://scoobiedoobie.com/contact" className="text-blue-600 underline">https://scoobiedoobie.com/contact</a></li>
            </ul>
          </div>
        </div>
      </div>
      <Footer/>

    </div>
  );
}
