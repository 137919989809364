import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

export default function About() {

  return (

 
    <div className="pt-20 relative min-h-screen pb-20"> {/* Add padding top to accommodate the fixed header */}
      <Header name="Scoobiedoobie Privacy Policy"/>
      <div className='w-full flex items-center justify-center'>
       <img
          src="./images/title.svg"
          alt="title text for logo"
          className="w-1/5"
        />
     </div>

      <section className="">
    <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

            <div className="lg:text-center">
                <h2
                    className="font-heading mb-4 bg-orange-100 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
                    Why choose us?
                </h2>
                <p className="font-heading mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
                Welcome to Scoobie Doobie: Where Love is Just Around the Corner!
                </p>
                <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                Hey there, love-seekers and romance-enthusiasts! Ready to put some pizzazz in your dating life? Say hello to Scoobie Doobie, the app that turns your daily grind into a lovefest!

                </p>
            </div>

            <div className="mt-10">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                    <div className="relative">
                        <dt>
                            <div
                                className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <img src="./images/explore.svg" 
                                 alt="expore logo"
                                />
                            </div>
                            <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Cross Paths</p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                        Our app is like a love detective, quietly noting when you cross paths with other Scoobie Doobiers. It's basically Sherlock Holmes for your heart!<br />
                        See someone who makes your heart go boom-chicka-boom? Give 'em a like! If they think you're groovy too, it's a match made in Scoobie heaven! <br />
                       
                        </dd>
                    </div>
                    <div className="relative">
                        <dt>
                            <div
                                className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                               <img src="./images/chat.svg"  alt="chat logo" />
                            </div>
                            <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Realtime Chat
                            </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500">
                        <strong>Chat:</strong> Time to turn on that charm! Swap jokes, trade memes, or debate the merits of pineapple on pizza. The chat's your oyster! <br />
                        <strong>Date:</strong> Ready to take it to the real world? Our app finds nearby restaurants faster than you can say "love at first bite"! <br />
                        </dd>
                    </div>
                    <div className="relative">
                        <dt>
                            <div
                                className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                <img src="./images/scoobydooby.svg" 
                                 alt="main logo"/> 

                            </div>
                            <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Why Scoobie Doobie is the Bees Knees

                            </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500"> 
                        Meet your neighborhood crushes (without the awkward "Don't I know you from somewhere?")
Discover love connections at work (water cooler romance, anyone?)
Book hot dates at cool local spots (bye-bye, "Where should we go?" dilemma!)
Keep it classy with our safe and respectful Scoobie community (creeps get the boot!)

                        </dd>
                    </div>
                    <div className="relative">
                        <dt>
                            <div
                                className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                                               <img src="./images/calendar.svg"  alt="calendar logo" /> 


                            </div>
                            <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">Ready to Find Your Scoobie Sweetie?
                            </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-500"> 
                        Stop swiping till your thumb falls off! With Scoobie Doobie, your next great love story could start with your next step out the door!
                        Download now and turn your world into a real-life romance novel! Who knows? Your "happily ever after" might be just around the corner... literally! 
                        </dd>
                    </div>
                </dl>
            </div>

        </div>
    </div>
        </section>
            <div className='w-full text-center'>
                <span className='italic'>Remember: Life's short, love's grand, and Scoobie Doobie's always at hand! 🎭🥳</span>
            </div>
      <Footer/>
    </div>
    
  );
}
